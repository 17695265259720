<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="spotlight"></company-submenu>
       
      <div class="submenu-content">
        <h2>{{ item.title }}</h2>

        <div v-html="item.body"></div>
        <!-- <embed type="video/webm" url="https://www.youtube.com/watch?v=wrD-Yr-Smzw" width="400" height="300"> -->

      </div>
    
  </div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from "../../utils/axios-api";
import { ref, onMounted } from 'vue'

export default {
  name: 'Contact',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({})

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Recognitions & Spotlights", link: "RecognitionsAndSpotlights"})
    
    onMounted(() => { 
      getAPI
      .get('/content/pages/10')
      .then(response => {
            item.value = response.data;
            let el = document.createElement('div');
            el.innerHTML = item.value.body;           
            let embed = el.getElementsByTagName('EMBED')[0];
            let urls = embed.getAttribute('url');
            urls = urls.replace('watch?v=', 'embed/');
            let youtubeIframe = document.createElement('iframe');
            youtubeIframe.setAttribute('src', urls);
            youtubeIframe.setAttribute('height', 400);
            youtubeIframe.setAttribute('width', 800);            
            el.insertBefore(youtubeIframe, el.childNodes[0]);
            item.value.body = el.innerHTML;     
      })
    })


    return { item, breadcrumb_links }
  }
}
</script>

<style>

</style>